import { canUseDOM } from 'exenv';
import { compose } from 'ramda';
import React from 'react';

import {
  CoveoNoIndexWrapper,
  withDataSourceValidation,
  withEditMode,
} from '_containers/BaseComponent';

import { getDictionaryItem } from '_utils/data/dictionaryItem';
import { getImageAttrsByRatio } from '_utils/helpers/image';
import { normalizeJssFields } from '_utils/helpers/jss';
import { useBreakpoint } from '_utils/hooks/useBreakpoint';
import {
  createSrcSet,
} from '_utils/media';
import { imagePlaceholderSrc } from '_utils/styles';
import {
  ImageRatio,
  ImageSize
} from '_utils/types/enums';

import { MagazineIssuesCarouselProps } from './definitions';
import { magazineCarouselFieldValidators } from './validation';

import ItemsWrapper from './ItemsWrapper';

import {
  Container,
  Heading,
  ListItem,
} from './StyledMagazineIssuesCarousel';

import {
  Card,
  ImageContainer,
  Image,
  Title
} from './StyledItem';

const MagazineIssuesCarousel: React.FC<MagazineIssuesCarouselProps> = ({ rendering, editMode }) => {

  const fields = rendering?.fields?.data?.datasource || {};
  const uid = rendering?.uid || null;

  const normalized = normalizeJssFields(fields);
  const heading = normalized?.heading;
  const items = normalized?.magazines || [];
  const trackingName = normalized?.trackingName;
  const validItems = !editMode ? items.filter((item) => item?.link?.value?.href) : items;
  const hasItems = editMode || (validItems?.length > 0);

  const readTitleDictionaryItem = getDictionaryItem('magazine-issue-carousel-read-label', 'Read {0}');

  // carousel functionality
  // define how many cards required to trigger carousel behaviour
  const bp = useBreakpoint();
  const numValidItemsToRenderCarousel = {
    xs: 3,
    sm: 3,
    md: 3,
    lg: 4,
    xl: 4,
  };

  const carouselEnabled =
    !editMode &&
    canUseDOM &&
    validItems?.length >= numValidItemsToRenderCarousel[bp];

  const responsiveColumnConfig =
  {
    col: {
      xs: 5,
      md: 5,
      lg: 4,
      xl: 4,
    }
  };

  const defaultImageSize = getImageAttrsByRatio(ImageSize.MW_384, ImageRatio.Ratio_2By3);

  return (
    <CoveoNoIndexWrapper editMode={editMode}>
      {(editMode || hasItems) &&
        <Container
          className="magazine-issues-carousel"
          data-component
          id={uid}
          numItems={validItems?.length ?? 0}
        >
          {(heading) && (
            <Heading>
              {heading?.value}
            </Heading>
          )}
          {hasItems &&
            <ItemsWrapper
              carouselEnabled={carouselEnabled}
              trackingName={trackingName?.value}
            >
              {validItems.map((item, index) => (
                <ListItem
                  carouselEnabled={carouselEnabled}
                  config={responsiveColumnConfig}
                  editMode={editMode}
                  key={item.id || index}
                >
                  <Card
                    href={item?.link?.value?.href}
                    target="_blank"
                  >
                    <ImageContainer>
                      <Image
                        className="lazyload"
                        field={
                          editMode
                            ? item?.image
                            : {
                              value: {
                                src: imagePlaceholderSrc,
                                alt: item?.image?.value?.alt ?? '',
                                'data-srcset': createSrcSet(item?.image?.value?.src, [
                                  ImageSize.MW_128,
                                  ImageSize.MW_256,
                                  ImageSize.MW_384
                                ])
                              }
                            }
                        }
                        {...defaultImageSize}
                      />
                    </ImageContainer>
                    <Title>{readTitleDictionaryItem.replace(/\{0\}/ig, item?.title ?? '')}</Title>
                  </Card>
                </ListItem>
              ))}
            </ItemsWrapper>
          }
        </Container>
      }
    </CoveoNoIndexWrapper >
  );
};

export default
  compose(
    withDataSourceValidation(magazineCarouselFieldValidators),
    withEditMode
  )(MagazineIssuesCarousel);
