import { compose } from 'ramda';
import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { withDigitalDataContext } from '_containers/DigitalDataContext';
import { emitTrackEvent, setObjectData } from '_utils/helpers/analytics';
import { normalizeJssFields } from '_utils/helpers/jss';
import { useScript } from '_utils/hooks';

import { SocialShareProps } from './definitions';
import { Container } from './StyledSocialShare';

/**
 * Frequency & max attempts to recheck if social share provider has rendered in the DOM.
 */
 const frequency = 200;
 const maxAttempts = 15;

const SocialShare: FC<SocialShareProps> = ({
  digitalData,
  rendering,
  setDigitalData,
}):JSX.Element | null => {
  const [hasRegistered, setHasRegistered] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const socialShareRef = useRef<HTMLDivElement>();
  const socialShare = normalizeJssFields(rendering?.fields?.data?.socialShare);
  
  if(!socialShare){
    return null;
  }

  const {
    socialShareConfigs,
  } = socialShare;

  /**
   *  Load Social Share provider's script.
   */  
  useScript(socialShareConfigs.scriptUrl);

  /**
   * Refresh AddThis only once on component load.
   */
  useEffect(() => {
    window.addEventListener('load', (window as any).addthis?.layers.refresh());
  }, []);

  /**
   * Register click events on social media icons after they have rendered.
   */
  useEffect(() => {
    
    let interval: NodeJS.Timeout;

    if(!hasRegistered){
      
      interval = setInterval(() => {
        setAttempts(attempts => attempts + 1);

        const container: HTMLDivElement = socialShareRef.current;
        
        //  Waiting for social share content to render. Only allow up to {maxAttempts} attempts at {frequency} milliseconds each unless already rendered.
        if(container?.childNodes.length > 0 && attempts <= maxAttempts) {
          
          setHasRegistered(true);
          clearInterval(interval);
          const buttonElements: Element = document.getElementsByClassName('at-share-btn-elements')[0];
          
          //  Add 'click' event listeners to all share anchor's generated by share provider.
          buttonElements.childNodes?.forEach((anchor: ChildNode) => {
            const innerText = (anchor?.childNodes[0] as HTMLSpanElement)?.innerText;
            const networkName = innerText?.substring(innerText?.lastIndexOf(' '));

            anchor.addEventListener('click', () => {
              if(networkName !== undefined && networkName !== ''){
                dispatchTracking(networkName.trim() as string);
              }
            });
          });
        } else { 
          clearInterval(interval);
        }
      }, frequency);
    }

    return () => {
      clearInterval(interval);
    }

  }, [hasRegistered, attempts]);

  const dispatchTracking = (network: string): void => {
    if(typeof setDigitalData === 'function'){
      setDigitalData(
        setObjectData(
          ['social'],
          {
            sharedPlatform: network,
          },
          digitalData
        )
      );
      
      emitTrackEvent('socialShare');
    }
  };

  return (
    <Container>
      <div ref={socialShareRef} className="addthis_inline_share_toolbox" />
    </Container>
  );
};

export default compose(withDigitalDataContext)(SocialShare);