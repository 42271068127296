import styled from 'styled-components';

import {
  hideOnPrint,
  mq,
} from '_utils/styles';

export const Container = styled.div`
  white-space: nowrap;

  ${hideOnPrint}

  a {
    background-color: ${({ theme }) => `${theme.socialShare.anchor.backgroundColour} !important`};
    border: ${({ theme }) => `${theme.socialShare.anchor.border} !important`};
    margin-left: 5px;
    transform: none !important;

    & span:last-child {
      height: ${({ theme }) => `${theme.socialShare.svg.height} !important`};
      width: ${({ theme }) => `${theme.socialShare.svg.width} !important`};

      & svg {
        height: ${({ theme }) => `${theme.socialShare.svg.height} !important`};
        width: ${({ theme }) => `${theme.socialShare.svg.width} !important`};
      }
    }
  }

  a:first-child {
    padding-left: 0;
  }

  a:last-child {
    margin-left: 0;
  }

  a:focus {
    border: 2px solid ${({ theme }) => `${theme.socialShare.anchor.outlineColour}`} !important;
    outline: none !important;
  }

  ${mq('lg')} {
     padding-top: 0;
  }
`;