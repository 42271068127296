import colors from '_containers/Theme/intheblack/colors';

export default {
  anchor: {
    border: `2px solid ${colors.element.neutralWhite}`,
    outlineColour: colors.element.primary2,
    backgroundColour: colors.all.transparent,
  },
  svg: {
    height: '24px',
    width: '24px',
  },
};