import { SitecoreFieldValidator } from '_utils/validationChecks/definitions';
import {
  isValidImage,
  isValidUrl,
} from '_utils/validationChecks';

export const magazineCarouselFieldValidators: SitecoreFieldValidator[] = [
  {
    field: 'rendering.fields.data.datasource.heading.jss.value',
    errorMessage: 'Heading is required',
    required: true
  },
  {
    field: 'rendering.fields.data.datasource.magazines',
    errorMessage: 'At least one card must be provided',
    minLength: 1
  },
  {
    field: 'rendering.fields.data.datasource.magazines',
    errorMessage: 'Some cards do not have valid links',
    allowRenderOnError: true,
    validators: [
      (field) => isValidUrl(field?.link?.value?.href)
    ]
  },
  {
    field: 'rendering.fields.data.datasource.magazines',
    errorMessage: 'Some cards do not have valid titles',
    allowRenderOnError: true,
    validators: [
      (field) => field?.title
    ]
  },
  {
    field: 'rendering.fields.data.datasource.magazines',
    errorMessage: 'Some cards do not have valid image',
    allowRenderOnError: true,
    validators: [
      (field) => isValidImage(field?.image)
    ]
  }
];
